<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ $t('app.buttons.edit') }}
              {{ $t('app.general.profile') }}
            </div>

            <div class="subtitle-1 font-weight-light">
              {{ $t('app.general.complete_your_profile') }}
            </div>
          </template>

          <validation-observer
            ref="observer"
            v-slot="{ validate, reset }"
          >
            <form>
              <v-list
                three-line
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.name') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.roles.name')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="item.nombre"
                          :placeholder="$t('app.headers.name')"
                          :error-messages="errors"
                          required
                        />
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.last_name') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.roles.name')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="item.apellido"
                          :placeholder="$t('app.headers.last_name')"
                          :error-messages="errors"
                          required
                        />
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.email') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-text-field
                        v-model="item.email"
                        type="email"
                        :placeholder="$t('app.headers.email')"
                        disabled
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.street') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.headers.street')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="item.calle"
                          :placeholder="$t('app.headers.street')"
                          :error-messages="errors"
                          required
                        />
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.suburb') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.headers.suburb')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="item.colonia"
                          :placeholder="$t('app.headers.suburb')"
                          :error-messages="errors"
                          required
                        />
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.state') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.headers.state')"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="item.estado_id"
                          :placeholder="$t('app.headers.state')"
                          :items="estados"
                          item-text="nombre"
                          item-value="id"
                          @change="getMunicipiosByState"
                          :error-messages="errors"
                          required
                        ></v-autocomplete>
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.municipality') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.headers.municipality')"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="item.municipio_id"
                          :placeholder="$t('app.headers.municipality')"
                          :items="municipios"
                          item-text="nombre"
                          item-value="id"
                          @change="getLocalidadesByMunicipality"
                          :disabled="!municipios"
                          :error-messages="errors"
                          required
                        ></v-autocomplete>
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.location') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.headers.location')"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="item.localidad_id"
                          :placeholder="$t('app.headers.location')"
                          :items="localidades"
                          item-text="nombre"
                          item-value="id"
                          :disabled="!localidades"
                          :error-messages="errors"
                          required
                        ></v-autocomplete>
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.borndate') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-dialog
                        ref="datePickerModalStart"
                        v-model="datePickerModalStart"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('app.headers.borndate')"
                            rules="required"
                          >
                            <v-text-field
                              v-model="item.fecha_nacimiento"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker :locale="$store.state.lang" v-model="item.fecha_nacimiento" @input="datePickerModalStart = false"></v-date-picker>
                      </v-dialog>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.avatar') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <base-file-input
                        :placeholder="$t('app.headers.avatar')"
                        icon="mdi-image"
                        accept="image/*"
                        @change="onChangeControl"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list
                three-line
                subheader
              >
                <v-subheader>
                  {{ $t('app.buttons.update') }}
                  {{ $t('app.login.password') }}
                </v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.password') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.headers.password')"
                        vid="password"
                      >
                        <v-text-field
                          v-model="item.password"
                          type="password"
                          :placeholder="$t('app.headers.password')"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.password_confirmation') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.headers.password_confirmation')"
                        rules="confirmed:password"
                      >
                        <v-text-field
                          v-model="item.password_confirmation"
                          type="password"
                          :placeholder="$t('app.headers.password_confirmation')"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                      block
                      color="primary"
                      @click="saveItem"
                    >
                      {{ $t('app.buttons.save') }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </form>
          </validation-observer>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          :avatar="user.avatar"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              Sucursal
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ user.persona.fullname }}
            </h4>

            <v-list
              two-line
              subheader
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.email }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('app.headers.email') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      [
                        item.persona.calle,
                        item.persona.colonia,
                        item.persona.localidad.nombre,
                        item.persona.municipio.nombre,
                        item.persona.estado.nombre
                      ].join(', ')
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('app.headers.address') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.persona.telefonos ? item.persona.telefonos.join(', ') : $t('app.general.undefined') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('app.headers.phones') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.persona.fecha_nacimiento }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t('app.headers.borndate') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    name: 'UsuarioPerfil',
    data () {
      return {
        item: {
          email: null,
          password: null,
          password_confirmation: null,
          avatar: null,
          foto: null,
          nombre: null,
          apellido: null,
          calle: null,
          colonia: null,
          localidad_id: null,
          municipio_id: null,
          estado_id: null,
          fecha_nacimiento: null,
          roles: []
        },
        estados: [],
        municipios: [],
        localidades: [],
        datePickerModalStart: false,
      }
    },
    computed: {
      ...mapState(['user'])
    },
    async mounted () {
      this.toggleLoader()
      this.estados = await this.getStates()
      this.item = {
        ...this.user,
        ...this.user.persona
      }
      this.item.foto = ''
      this.item.avatar = ''
      this.item.password = ''

      await this.getMunicipiosByState()
      await this.getLocalidadesByMunicipality()

      this.toggleLoader()
    },
    methods: {
      ...mapMutations({
        setUser: 'SET_USER'
      }),
      onChangeControl (files) {
        if (files && files.length > 0) {
          this.item.avatar = files[0]
        }
      },
      async getMunicipiosByState () {
          this.toggleLoader()
          this.municipios = await this.getMunicipalityByEstado(this.item.estado_id)
          this.toggleLoader()
        },
      async getLocalidadesByMunicipality () {
        this.toggleLoader()
        this.localidades = await this.getLocationsByMunicipality(this.item.municipio_id)
        this.toggleLoader()
      },
      async saveItem () {
        this.$refs.observer.validate()
        .then(async success => {
          if (!success) return

          this.toggleLoader()

          let form = new FormData()
          form.append('id', this.item.id)
          form.append('email', this.item.email)
          form.append('nombre', this.item.nombre)
          form.append('apellido', this.item.apellido)
          form.append('calle', this.item.calle)
          form.append('colonia', this.item.colonia)
          form.append('localidad_id', this.item.localidad_id)
          form.append('municipio_id', this.item.municipio_id)
          form.append('estado_id', this.item.estado_id)
          form.append('fecha_nacimiento', this.item.fecha_nacimiento)

          if (this.item.password != '') {
            form.append('password', this.item.password)
            form.append('password_confirmation', this.item.password_confirmation)
          }

          if (this.item.avatar) form.append('avatar', this.item.avatar)
          if (this.item.foto) form.append('foto', this.item.foto)

          await this.$http.post(route('v1/user/'+this.item.id), form, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            if (response.body.code === 200) {
              this.processSuccess(response)
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })

          this.$http.get(route('v1/user/'+this.item.id))
            .then(response => {
              if (response.body.code === 200) {
                this.setUser(response.body.data[0])
              } else {
                this.processError(response)
              }
            }, error => {
              this.processError(response)
            })

          this.dialog = false
          this.toggleLoader()
        })
      }
    }
  }
</script>
